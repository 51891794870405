<template>
  <AuctionHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
  <section class="bg-agrogo-green-2">
    <!-- STEP NUMBER -->
    <div class="grid grid-cols-3 bg-agrogo-yellow-2 h-20 md:h-24">
      <div class="flex h-auto w-auto items-center justify-end">
        <span
          class="font-nexa font-black text-6xl md:text-7xl text-agrogo-yellow-2"
          >02</span
        >
      </div>
      <div
        class="col-span-2 flex flex-col items-start justify-center ml-5 md:ml-8"
      >
        <span class="font-nexa font-black text-base md:text-2xl mb-3 text-left"
          >Categoría
        </span>
        <div class="flex items-center space-x-4 md:space-x-7 md:text-base">
          <span class="icon-punto text-agrogo-green-3"></span>
          <span class="icon-punto text-white"></span>
        </div>
      </div>
    </div>
    <!-- CONTENIDO STEP 2 -->
    <div
      class="flex flex-col items-center justify-center my-3 mx-2 md:mt-14 md:mb-8"
    >
      <div
        class="container-step2-auction h-14 md:h-20 mb-4 md:mb-6 grid grid-cols-2"
      >
        <div
          class="flex items-center ml-6 md:ml-44 font-nexa font-bold text-sm md:text-xl text-white"
        >
          Categoría
        </div>
        <div class="flex items-center justify-start mr-12">
          <v-select
            class="style-chooser2 w-full md:max-w-236 text-xs"
            label="name"
          >
            <template #no-options>Sin opciones</template>
          </v-select>
        </div>
      </div>
      <div class="container-step2-auction py-4 md:py-8">
        <div
          class="flex items-center ml-6 md:ml-44 font-nexa font-bold text-sm md:text-xl text-white mb-4 md:mb-6"
        >
          Subcategoria
        </div>
        <div
          class="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mx-1 md:mx-12 gap-x-4 gap-y-4 lg:gap-x-12 lg:gap-y-8 place-items-center"
        >
          <div class="card" v-for="(item, index) in dataIcon" :key="index">
            <span
              class="text-5xl text-agrogo-gray-1 mb-2 md:text-6xl md:mb-6"
              :class="item.icon"
            ></span>
            <a
              class="font-nexa text-agrogo-gray-1 text-sm mb-2 md:text-lg md:mb-5 mx-1"
              >{{ item.name }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- BOTONES DE NAVEGACION -->
    <div
      class="bg-agrogo-yellow-1 h-14 md:h-20 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button @click="previus()" type="button" class="btn-primary">
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button @click="next()" type="button" class="btn-primary">
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
        ></span>
      </button>
    </div>
  </section>
</template>
<script>
import AuctionHeader from "@/components/header/HeaderAuction";
export default {
  components: {
    AuctionHeader,
  },
  data() {
    return {
      dataIcon: [
        { id: 1, icon: "icon-ganadera", name: "Bovinos" },
        { id: 2, icon: "icon-equinos", name: "Equinos" },
        { id: 3, icon: "icon-porcicola", name: "Porcinos" },
        { id: 4, icon: "icon-asnos", name: "Asnales" },
        { id: 5, icon: "icon-ovinos", name: "Ovinos y Caprinos" },
        { id: 5, icon: "icon-mula", name: "Mulares" },

        { id: 6, icon: "icon-caninos", name: "Caninos" },
        { id: 7, icon: "icon-felinos", name: "Felinos" },
        { id: 8, icon: "icon-avicola", name: "Aves" },
        { id: 9, icon: "icon-piscicola", name: "Peces" },
      ],
    };
  },
};
</script>
